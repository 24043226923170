.wizard {
    max-width: 800px;
    margin: auto;
}
.wizard-error {
    color: red;
}

.select-wizard {
    max-width: 400px;
}

.select-wizard-sl {
    max-width: 150px;
}
.select-small {
    max-width: 300px;
    font-size: 1.1em;
}

.select-small-bg {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.select-wizard-bg {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.picture-info {
    width: 18em;
    height: 380px;
}

.picture-info-pic {
    object-fit: cover;
    height: 200px;
}

.inter10 {
    margin-left: 10px;
}

.num-width {
    max-width: 5em;
}
.spinner-overlay {
    position: fixed;
    width: 50px;
    height: 50px;
    left: 100px;
    top: 100px;
}
