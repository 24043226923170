.blue-green-gradient1 {
    background-color: #fa8bff;
    background-image: linear-gradient(45deg, #fa8bff 0%, #2bd2ff 52%, #2bff88 90%);
}

.orange-gradient1 {
    background-color: #ff9a8b;
    background-image: linear-gradient(90deg, #ff9a8b 0%, #ff6a88 55%, #ff99ac 100%);
}

.blue-gradient1 {
    background-color: #21d4fd;
    background-image: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%);
}
.green-gradient1 {
    /*border-color: white;*/
    background-color: #08aeea;
    background-image: linear-gradient(0deg, #08aeea 0%, #2af598 100%);
}
.green-gradient2 {
    background-color: #0093e9;
    background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}

.blue-gradient2 {
    background-color: #8bc6ec;
    background-image: linear-gradient(135deg, #8bc6ec 0%, #9599e2 100%);
}

.digital-water-gradient {
    background: linear-gradient(to right, rgb(116, 235, 213), rgb(172, 182, 229));
}

.blue-gradient3 {
    background-color: #eeeeee;
    background-image: linear-gradient(135deg, #eeeeee 0%, #9ab3e6 40%);
    border: none;
}
.orange {
    /*ff924c;*/
    /* border-image: linear-gradient(to bottom, red, #ffa502) 0 0 0 100%/0 0 0 5px stretch;*/
    border-left: #ffa502 5px solid;
}
