.card-place {
    width: 20rem;
}
.yellow {
    background-color: yellow;
}

.card-title-wrap {
    vertical-align: middle;
}

.card-place-title {
    height: 3.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #444;
}

.card-picture {
    object-fit: cover;
    height: 15rem;
}

.fc-card {
    width: 25rem;
    border-radius: 25px;
}

.fc-main-picture {
    max-width: 100%;
    height: auto;
    object-fit: cover;
}

.fc-back-card {
    width: 20rem;
    height: 20rem;
    border-radius: 1.5rem;
}

.fc-back-card:hover {
    cursor: pointer;
}
.fc-back-image:hover {
    opacity: 1;
}
.fc-back-image {
    background-image: url("./welcome-card.jpg");
    background-repeat: no-repeat;
    background-attachment: inherit;
    background-position: center;
    background-size: cover;
    opacity: 0.8;
    width: 100%;
    height: 15rem;
    border-radius: 1.5rem 1.5rem 0 0;

    /*opacity: 0.75;*/
}

.fc-back-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}
.fc-button-title {
    font-size: 18px;
}

/* https://developer.mozilla.org/fr/docs/Web/CSS/CSS_Backgrounds_and_Borders/Resizing_background_images  */
/* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
