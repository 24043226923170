.card-add-icon {
    padding-top: 20px;

    text-align: center;
}
.card-add {
    text-align: center;
    /* background-color: rgb(248, 249, 250);*/
    background-color: rgb(226, 243, 249);
}

.card-add:hover {
    cursor: pointer;
    background-color: rgb(110, 193, 228);
    color: white;
}
