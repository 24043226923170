* {
    margin: 0;
    padding: 0;
    border: 0;
}

img:hover {
    cursor: pointer;
}

.main-picture {
    max-width: 100%;
    height: auto;
    object-fit: cover;
}

.secondary-picture {
    max-width: 100%;
    height: 100px;
    object-fit: cover;
}

.mosaic {
    max-width: 600px;
}

.helper {
    display: inline-block;
    height: 100%;
    vertical-align: bottom;
}

.end-helper {
    display: block;
}

.inlineblock {
    display: inline-block;
}

.img__description {
    font-size: 0.8em;
}
