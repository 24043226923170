.conf-table {
    max-width: 700px;
    background-color: #f3f3f3;
    font-size: 1em;
}
.conf-table th {
    font-size: 0.9em;
}
.advanced-conf {
    color: #777;
}

.advanced-conf th {
    color: #777;
}

.field-title {
    font-size: 1.1em;
    color: #777;
    font-weight: 400;
}

.section-title {
    font-size: 1.3em;
    color: #444;
    font-weight: 400;
    text-align: center;
    margin: 15px;
}
