.todoappdiv {
    margin: auto;
    margin-top: 5%;
}

.center {
    text-align: center;
}

.error {
    color: red;
}

.selected {
    color: orange;
}

.margin-auto {
    margin: auto;
}

.fullHeightGrid {
    display: grid;
    height: 100vh;
}

.placeSelfCenter {
    place-self: center;
}

.select-signup {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.contact-title {
    font-size: 1.1em;
    color: #777;
}

.sub-section-title {
    font-size: 1em;
    color: #444;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex1 {
    flex: 1;
}
.flex2 {
    flex: 2;
}
.flex3 {
    flex: 3;
}
.flex4 {
    flex: 4;
}
.flex5 {
    flex: 5;
}
.flex6 {
    flex: 6;
}
.text-align-left {
    text-align: left;
}
.flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.create-account-card {
    max-width: 640px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
